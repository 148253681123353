function domReady(callback) {
  if (document.readyState === "loading") {
    document.addEventListener("DOMContentLoaded", callback);
  } else {
    callback();
  }
}

export default {
  init: function () {
    console.log("Cookie Banner (v3.4) loaded");

    domReady(() => {
      checkConsentCookie("acceptedCookies", function (element) {
        element.classList.remove("disabled");
      });
      checkOptOutCookie("optOutTracking", function (element) {
        element.classList.add("disabled");
        element.setAttribute("src", "/etc.clientlibs/acs-cdn/aem6/cdn/static/resources/images/transparent_16-9.gif");
      });

      document.querySelectorAll(".cookie-consent").forEach(element => {
        element.addEventListener("click", handleCookieConsentClickEvent);
      });

      document.querySelectorAll(".cookie-save").forEach(element => {
        element.addEventListener("click", handleCookieSaveClickEvent);
      });

      document.querySelectorAll(".cookie-detail-optout__status").forEach(element => {
        element.addEventListener("click", handleOptOutStatusClickEvent);
      });
    });
  },
};

function checkConsentCookie(name, callback) {
  if (document.cookie === null || document.cookie.indexOf(name) === -1) {
    document.querySelectorAll(`.alert-banner__cookie`).forEach(callback);
  }
}

function checkOptOutCookie(name, callback) {
  if (document.cookie.indexOf(name) !== -1) {
    document.querySelectorAll(`.cookie-detail-optout__status`).forEach(callback);
  }
}

function addClassToElements(selector, className) {
  document.querySelectorAll(selector).forEach(element => element.classList.add(className));
}

function handleCookieConsentClickEvent() {
  addClassToElements(".alert-banner__cookie", "disabled");
  setCookie("acceptedCookies", true);
}

function handleCookieSaveClickEvent(event) {
  setCookieBasedOnStatus(".cookie-detail-optout__status", "optOutTracking");
  addClassToElements(".alert-banner__cookie", "disabled");
  setCookie("acceptedCookies", true);
  event.target.classList.remove("btn-yellow");
  event.target.classList.add("disabled");
  event.target.disabled = true;
}

function handleOptOutStatusClickEvent() {
  toggleYellowButton(".cookie-detail__save button");
  toggleDisabledStatus(".cookie-detail-optout__status");
}

function setCookie(name, value) {
  let expiry = new Date();
  expiry.setFullYear(expiry.getFullYear() + 10);
  document.cookie = `${name}=${value}; domain=.acs.org; path=/; expires=${expiry.toUTCString()}`;
}

function setCookieBasedOnStatus(selector, name) {
  let expiry = new Date();
  expiry.setFullYear(expiry.getFullYear() + 10);
  const isDisabled = Array.from(document.querySelectorAll(selector)).some(el => el.classList.contains("disabled"));
  if (isDisabled) {
    setCookie(name, true);
  } else {
    document.cookie = `${name}=; domain=.acs.org; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
  }
}

function toggleYellowButton(selector) {
  document.querySelectorAll(selector).forEach(button => {
    button.classList.toggle("btn-yellow");
    button.classList.toggle("disabled");
    button.disabled = !button.disabled;
  });
}

function toggleDisabledStatus(selector) {
  document.querySelectorAll(selector).forEach(status => {
    status.classList.toggle("disabled");
    status.setAttribute("src", "/etc.clientlibs/acs-cdn/aem6/cdn/static/resources/images/transparent_16-9.gif");
  });
}
