import EqualizeBottoms from "./cq5-equalizebottoms.js";

export default {
  init: function () {
    var self = this;

    function desktopOff() {
      window.removeEventListener("resize", self.equalizeHeight);
      self.resetHeight();
    }

    if (document.documentElement.classList.contains("lt-ie9")) {
      // IE 8 below
      self.equalizeHeight();
    }
  },
  equalizeHeight: function () {
    // Match all row-fluid.balanced except 1 column (span12)
    let bd = document.querySelector("#bd");
    let bdBody = bd?.querySelectorAll('[class$="-body"]');
    let bdNav = bd?.querySelectorAll('[class$="-nav"]');
    let allCol = Array.from(document.querySelectorAll("#bd .balanced, #bd .borders"))
      .filter(element => !element.classList.contains("balanced--flex"))
      .flatMap(element => Array.from(element.querySelectorAll('> [class*="col-md-"]:not(.col-md-12)')));

    function equalize() {
      bd?.querySelectorAll(".row-fluid.balanced, .row.balanced, .row.borders").forEach(element => {
        if (!element.closest(".balanced--flex")) {
          new EqualizeBottoms(element.querySelectorAll('> [class*="col-md-"]:not(.col-md-12)'));
        }
      });
    }

    function resizeContainer() {
      // Match the height of last callout box in each column
      bd?.querySelectorAll(".row-fluid.balanced, .row.balanced").forEach(element => {
        if (!element.closest(".balanced--flex")) {
          new EqualizeBottoms(element.querySelectorAll('> [class*="col-md-"]:not(.col-md-12) > :last-child > :last-child > [class*="box-style"]'));
        }
      });
    }

    if (bdBody.length === 1) {
      //reset before resize again
      document.querySelectorAll("#bd .balanced, #bd .borders").forEach(element => {
        if (!element.classList.contains("balanced--flex")) {
          element.querySelectorAll('> [class*="col-md-"]:not(.col-md-12)').forEach(colElement => {
            colElement.style.height = "auto";
          });
        }
      });

      // if body content is longer, then do match height
      if (bdBody[0].offsetHeight > bdNav[0].offsetHeight) {
        equalize();
      }
    } else {
      equalize();
    }
    if (!document.body.classList.contains("noMatch")) {
      resizeContainer();
    }
  },
  resetHeight: function () {
    document.querySelectorAll("#bd .balanced, #bd .borders").forEach(element => {
      if (!element.classList.contains("balanced--flex")) {
        element.querySelectorAll('> [class*="col-md-"]:not(.col-md-12)').forEach(colElement => {
          colElement.style.height = "auto";
        });
      }
    });

    document.querySelectorAll("#bd .row-fluid.balanced, #bd .row.balanced").forEach(element => {
      if (!element.classList.contains("balanced--flex")) {
        element.querySelectorAll('> [class*="col-md-"]:not(.col-md-12) > :last-child > :last-child > [class*="box-style"]').forEach(boxElement => {
          boxElement.style.height = "auto";
        });
      }
    });
  },
};
