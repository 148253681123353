export default {
  init: function () {
    acs.cq || (acs.cq = {});

    console.log("Left Navi loaded");

    var container = document.querySelectorAll(".ui-nav .contained-sub"),
      el = document.querySelectorAll(".ui-nav .flyout"),
      elSelected = document.querySelectorAll(".ui-nav .selected-sub .flyout");

    // Configure the left nav
    acs.cq.leftnav = {
      enableFlyouts: true,
    };

    // If does not support touch
    if ("ontouchstart" in window) {
      document.querySelector("html").classList.add("touch");
      elSelected.forEach(e => (e.style.display = "block"));
    } else {
      document.querySelector("html").classList.add("no-touch");

      if (elSelected.length) {
        elSelected.forEach(e => (e.style.display = "none"));
      }

      if (window.innerWidth >= 768) {
        applyFlyOuts();
      } else {
        acs.cq.leftnav.enableFlyouts = false;
        el.forEach(e => (e.style.display = "block"));
      }

      // Handle Desktop / Table
      // Do left nav menu flyout
      let mql = window.matchMedia("(min-width: 768px)");
      function isDesktop(e) {
        if (e.matches) {
          acs.cq.leftnav.enableFlyouts = true;
          el.forEach(e => (e.style.display = "none"));
          applyFlyOuts();
        } else {
          acs.cq.leftnav.enableFlyouts = false;
          el.forEach(e => (e.style.display = "block"));
        }
      }
      mql.addEventListener("change", isDesktop);
      isDesktop(mql);
    }

    function applyFlyOuts() {
      container.forEach(e => {
        e.addEventListener("mouseenter", function () {
          if (acs.cq.leftnav.enableFlyouts && !!this.querySelector(".flyout")) {
            this.querySelector(".flyout").style.display = "block";
          }
        });

        e.addEventListener("mouseleave", function () {
          if (acs.cq.leftnav.enableFlyouts && !!this.querySelector(".flyout")) {
            this.querySelector(".flyout").style.display = "none";
          }
        });
      });
    }
  },
};
