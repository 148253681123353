export default {
  init: function () {
    document.querySelectorAll(".carousel").forEach(carousel => {
      let carouselContainer = carousel.querySelector(".container--slider");
      if (!carouselContainer) {
        return;
      }
      let resultContainer = carouselContainer.querySelector(".results--carousel");
      if (!resultContainer) {
        return;
      }
      let resultItems = carouselContainer.querySelectorAll(".res__item");
      let resultItemWidth = resultItems[0].offsetWidth;
      let resultContainerGap = parseInt(getComputedStyle(resultContainer).columnGap);

      let resultContainerWidth = (resultItems.length - 1) * (resultItemWidth + resultContainerGap) + resultItemWidth;

      let resultItemsGroups =
        resultContainerWidth - Math.floor(resultContainerWidth / carouselContainer.offsetWidth) * carouselContainer.offsetWidth >= resultItemWidth
          ? Math.floor(resultContainerWidth / carouselContainer.offsetWidth) + 1
          : Math.floor(resultContainerWidth / carouselContainer.offsetWidth);
      let currentResultGroup = 0;
      let nextResultGroup = currentResultGroup + 1;
      let previousResultGroup = currentResultGroup - 1;

      function carouselNavigation() {
        resultItemWidth = resultItems[0].offsetWidth;
        resultContainerWidth = (resultItems.length - 1) * (resultItemWidth + resultContainerGap) + resultItemWidth;
        resultItemsGroups =
          resultContainerWidth - Math.floor(resultContainerWidth / carouselContainer.offsetWidth) * carouselContainer.offsetWidth >= resultItemWidth
            ? Math.floor(resultContainerWidth / carouselContainer.offsetWidth) + 1
            : Math.floor(resultContainerWidth / carouselContainer.offsetWidth);

        if (document.location.search.indexOf("acsJsDebug") >= 0 || window.acsJsDebug) {
          console.groupCollapsed("carousel: " + carousel.querySelector(".carousel__heading h2")?.innerHTML);
          console.log("resultItemsGroups: " + resultItemsGroups);
          console.log("resultItems.length: " + resultItems.length);
          console.log("resultItemWidth: " + resultItemWidth);
          console.log("resultContainerGap: " + resultContainerGap);
          console.log("resultContainerWidth: " + resultContainerWidth);
          console.log("carouselContainer.offsetWidth: " + carouselContainer.offsetWidth);
          console.log("remainder [resultContainerWidth / carouselContainer.offsetWidth]: " + resultContainerWidth / carouselContainer.offsetWidth);
          console.log("remainder floor: " + Math.floor(resultContainerWidth / carouselContainer.offsetWidth));
          console.log("remainder floor plus one: " + (Math.floor(resultContainerWidth / carouselContainer.offsetWidth) + 1));
          console.log(
            "resultContainerWidth [" +
              resultContainerWidth +
              "] minus (floor [" +
              Math.floor(resultContainerWidth / carouselContainer.offsetWidth) +
              "] times carouselContainer.offsetWidth [" +
              carouselContainer.offsetWidth +
              "]) equals " +
              (resultContainerWidth - Math.floor(resultContainerWidth / carouselContainer.offsetWidth) * carouselContainer.offsetWidth),
          );
          console.log("any danglers: " + (resultContainerWidth - Math.floor(resultContainerWidth / carouselContainer.offsetWidth) * carouselContainer.offsetWidth >= resultItemWidth));
          console.groupEnd();
        }

        let dotHtml = "";
        let navHtml = "";
        if (resultItemsGroups > 1) {
          for (let index = 0; index < resultItemsGroups; index++) {
            dotHtml +=
              index === 0 ? '\n<button class="cnav__dot cnav__dot--' + index + ' on" data-result-group="' + index + '"></button>\n' : '\n<button class="cnav__dot cnav__dot--' + index + '" data-result-group="' + index + '"></button>\n';
          }
          navHtml += '\n<button class="cnav__button cnav__button--previous" data-js-direction="previous" aria-label="Previous Slide" type="button" role="button"></button>';
          navHtml += '<div class="cnav__dots">\n';
          navHtml += dotHtml;
          navHtml += "</div>\n";
          navHtml += '<button class="cnav__button cnav__button--next" data-js-direction="next" aria-label="Next Slide" type="button" role="button"></button>\n';
        }
        carouselContainer.querySelectorAll(".carousel__navigation").forEach(el => {
          el.innerHTML = navHtml;
        });

        carouselContainer.querySelectorAll(".cnav__dot").forEach(button => {
          button.addEventListener("click", function (e) {
            e.preventDefault();
            carouselContainer.querySelector(".cnav__dot.on")?.classList.remove("on");
            this.classList.add("on");
            carouselSlider(this.dataset.resultGroup);
          });
        });

        carouselContainer.querySelectorAll(".cnav__button").forEach(button => {
          button.addEventListener("click", function (e) {
            e.preventDefault();
            nextResultGroup = currentResultGroup + 1;
            previousResultGroup = currentResultGroup - 1;

            if (this.dataset.jsDirection === "next" && nextResultGroup < resultItemsGroups) {
              carouselContainer.querySelector(".cnav__dot.on")?.classList.remove("on");
              carouselContainer.querySelector(".cnav__dot--" + nextResultGroup)?.classList.add("on");
              carouselSlider(nextResultGroup);
            }
            if (this.dataset.jsDirection === "previous" && previousResultGroup > -1) {
              carouselContainer.querySelector(".cnav__dot.on")?.classList.remove("on");
              carouselContainer.querySelector(".cnav__dot--" + previousResultGroup)?.classList.add("on");
              carouselSlider(previousResultGroup);
            }
          });
        });
      }

      function resultsItemVisibility(items) {
        if (carouselContainer.querySelector(".cnav__button.hide")) {
          carouselContainer.querySelector(".cnav__button.hide")?.classList.remove("hide");
        }
        if (currentResultGroup === 0) {
          carouselContainer.querySelector(".cnav__button--previous")?.classList.add("hide");
        }
        if (currentResultGroup === resultItemsGroups - 1) {
          carouselContainer.querySelector(".cnav__button--next")?.classList.add("hide");
        }
      }

      function carouselSlider(resultGroup) {
        currentResultGroup = parseInt(resultGroup);
        /*
                console.log('carouselContainer.offsetWidth: ' + carouselContainer.offsetWidth);
                console.log('resultContainerGap: ' + resultContainerGap);
                console.log('currentResultGroup: ' + currentResultGroup);
                console.log('(carouselContainer.offsetWidth + resultContainerGap) * currentResultGroup: ' + resultContainer.style.left);
                */
        resultContainer.style.left = -((carouselContainer.offsetWidth + resultContainerGap) * currentResultGroup) + "px";
        window.onresize = function () {
          resultContainer.style.left = -((carouselContainer.offsetWidth + resultContainerGap) * currentResultGroup) + "px";
        };
        resultsItemVisibility(resultItems);
      }

      carouselNavigation();
      resultsItemVisibility(resultItems);

      const mqPhoneSizeMax = "(max-width: 480px)";
      const mqPhoneSize = "(min-width: 481px) and (max-width: 768px)";
      const mqPortraitTabletSize = "(min-width: 769px) and (max-width: 980px)";
      const mqDefaultDesktopSize = "(min-width: 981px)";
      const mqLargeDesktopSize = "(min-width: 1201px)";

      window.matchMedia(mqPhoneSizeMax).addEventListener("change", event => {
        if (event.matches) {
          console.log(mqPhoneSizeMax);
          carouselNavigation();
          carouselSlider(0);
        }
      });
      window.matchMedia(mqPhoneSize).addEventListener("change", event => {
        if (event.matches) {
          console.log(mqPhoneSize);
          carouselNavigation();
          carouselSlider(0);
        }
      });
      window.matchMedia(mqPortraitTabletSize).addEventListener("change", event => {
        if (event.matches) {
          console.log(mqPortraitTabletSize);
          carouselNavigation();
          carouselSlider(0);
        }
      });
      window.matchMedia(mqDefaultDesktopSize).addEventListener("change", event => {
        if (event.matches) {
          console.log(mqDefaultDesktopSize);
          carouselNavigation();
          carouselSlider(0);
        }
      });
    });
  },
};
