/**
 * Creates a configured executor function that, when called, iterates over grouped elements and executes
 * corresponding methods from provided modules. This factory function allows pre-configuration of modules
 * and lazy-loaded modules, separating the setup phase from the execution phase. The returned executor
 * function takes an object where keys represent "moduleName.methodName" pairs, and values are arrays of
 * DOM elements to be processed. It executes the named method on the corresponding module for each group
 * of elements. This approach allows for a modular and reusable design, enabling the same configuration to
 * be applied across different sets of elements.
 *
 * @param {Object} importedModules - An object containing the available modules. Each key should be a module name,
 *                                   and the corresponding value should be the module itself, potentially containing
 *                                   methods to be executed.
 * @param {Array<Object>} modulesToInitialize - An array of objects representing modules that are to be lazy-loaded.
 *                                              Each object must have a `name` property that corresponds to the
 *                                              module's name. This array is used to check if a module might not be
 *                                              immediately available but is expected to be loaded later.
 * @returns {Function} An executor function that accepts an object of grouped elements (`groupedElements`). This
 *                     object's keys should be in the format "moduleName.methodName", and each key's value should be
 *                     an array of DOM elements. The executor function then attempts to execute the specified
 *                     method from the corresponding module for each group of elements, handling errors gracefully.
 *
 * @example
 * // Define modules and their methods
 * const myModules = {
 *   module1: {
 *     init: (elements) => console.log('Initializing module1 with elements', elements),
 *   },
 *   module2: {
 *     doSomething: (elements) => console.log('Doing something with module2', elements),
 *   }
 * };
 *
 * // Specify modules expected to be lazy-loaded
 * const lazyModules = [
 *   { name: 'module3' } // Example of a lazy-loaded module
 * ];
 *
 * // Create an executor with pre-configured modules
 * const executeGroupedMethods = createExecutor(myModules, lazyModules);
 *
 * // Group elements by moduleName.methodName
 * const elementsGroupedByMethod = {
 *   'module1.init': [document.getElementById('element1'), document.getElementById('element2')],
 *   'module2.doSomething': [document.getElementById('element3')]
 * };
 *
 * // Execute methods for grouped elements
 * executeGroupedMethods(elementsGroupedByMethod);
 */

function createExecutor(importedModules, modulesToInitialize) {
  return function execute(groupedElements) {
    for (const method in groupedElements) {
      const [moduleName, methodName] = method.split(".");
      if (!moduleName || !methodName) {
        console.warn(`Invalid controller method format: '${method}' (expected 'moduleName.methodName')`);
        continue;
      }

      const module = importedModules[moduleName];
      const isFunction = typeof module?.[methodName] === "function";

      if (isFunction) {
        try {
          module[methodName](groupedElements[method]);
        } catch (err) {
          console.error(`Could not execute ${moduleName}.${methodName}:`, err);
        }
      } else if (!modulesToInitialize.some(({ name }) => name === moduleName)) {
        console.error(`Function '${methodName}' not found in module '${moduleName}' or in lazy-loaded modules.`);
      }
    }
  };
}
export default createExecutor;
