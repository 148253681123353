function getQueryVariable(variable) {
  const params = new URLSearchParams(window.location.search);
  return params.get(variable) || false;
}

export default {
  init: function (_dynamicjoins) {
    const sc = getQueryVariable("sc"); // Source code
    if (!sc) return;

    _dynamicjoins.forEach(element => {
      const oldUrl = new URL(element.getAttribute("href"), window.location.origin);
      if (oldUrl.hostname.includes("join.acs.org")) {
        oldUrl.searchParams.set("sc", sc);
        element.setAttribute("href", oldUrl.href);
      }
    });
  },
};
