export default {
  init: function () {
    let navMainLinks = document.querySelectorAll(".nav--main__link");

    if (window.matchMedia("(max-width:767px)").matches) {
      mobileMenu();
    } else if (window.matchMedia("(min-width:768px)").matches) {
      desktopMenu();
    }

    window.matchMedia("(max-width:767px)").addEventListener("change", function (event) {
      if (event.matches) {
        mobileMenu();
      }
    });

    window.matchMedia("(min-width:768px)").addEventListener("change", function (event) {
      if (event.matches) {
        desktopMenu();
      }
    });

    function mobileMenu() {
      navMainLinks.forEach(navMainLink => {
        navMainLink.parentNode.classList.remove("open");
        navMainLink.addEventListener("click", function (e) {
          if (this.parentNode.classList.contains("nav--main__item--first--has-sub")) {
            e.preventDefault();
            this.parentNode.classList.toggle("open");
          }
        });
      });
    }

    function desktopMenu() {
      navMainLinks.forEach(navMainLink => {
        navMainLink.parentNode.classList.remove("open");
      });
    }
  },
};
