export default {
  init: function () {
    let dotPath = window.location.pathname.substring(window.location.pathname.lastIndexOf("/") + 1);
    if (dotPath.split(".").length > 2) {
      window.history.replaceState({}, "", dotPath.split(".")[0] + "." + dotPath.split(".")[2] + "#" + dotPath.split(".")[1]);
    }

    let navSubLinks = document.querySelectorAll(".acsidnav__sub-link");
    for (let navSubLink of navSubLinks) {
      navSubLink.addEventListener("click", function (e) {
        e.preventDefault();
        document.getElementById(this.dataset.id).scrollIntoView({ behavior: "smooth" });
        window.history.pushState({}, "", "#" + this.dataset.id);
        this.blur();
      });
    }

    let hatMenuButton = document.querySelector(".hat__menu-button");
    hatMenuButton?.addEventListener("click", function (e) {
      e.stopPropagation();

      if (!document.querySelector(".menu__nav .acsidnav__list")) {
        document.querySelector(".menu__nav")?.appendChild(document.querySelector(".acsidnav__list")?.cloneNode(true));
      }

      let navSubLinks = document.querySelectorAll(".acsidnav__sub-link");
      for (let navSubLink of navSubLinks) {
        navSubLink.addEventListener("click", function (e) {
          hatMenuButton?.click();
        });
      }
    });

    let options = {
      root: null,
      threshold: 0,
      rootMargin: "-30%",
    };
    const sectionObserver = new IntersectionObserver(callBackFunction, options);

    let sections = document.querySelectorAll(".acscontainer > div[id]");
    for (let section of sections) {
      sectionObserver.observe(section);
    }

    function callBackFunction(entries) {
      const [entry] = entries;
      if (entry.isIntersecting) {
        document.querySelectorAll(".acsidnav__sub-item").forEach(item => {
          item.classList.remove("active");
        });
        document.querySelector('[data-id="' + entry.target.id + '"')?.classList.add("active");
        window.history.pushState({}, "", "#" + entry.target.id);
      } else {
        document.querySelector('[data-id="' + entry.target.id + '"')?.classList.remove("active");
      }
    }
  },
};
