import $ from "jquery";
import EqualizeBottoms from "./cq5-equalizebottoms.js";

export default {
  init: function () {
    var videoElements;
    console.log("Media Players loaded using jQuery ", $.fn.jquery);
    videoElements = $("video,audio").not(".skipMediaPlayer, .skipMediaPlayer *");
    if (videoElements.length > 0) {
      console.log("Loading media element JS library, found media element");
      // dynamically load CSS using webpack loader
      const importedCss = import(/* webpackChunkName: "mediaelement-css" */ "../sass/components-standalone/mediaelement.scss");
      const importMediaElement = import(/* webpackChunkName: "mediaelement" */ "mediaelement/full.js");

      Promise.all([importMediaElement])
        .then(([mediaElement]) => {
          videoElements.mediaelementplayer({
            pluginPath: "//" + acs.assetDomain + "/v2.0/aem/components/mediaelement/build/",
          });
          return this.resizeAndBindVideos($(".vimeoembed"));
        })
        .catch(error => {
          console.error("Could not load media elements JS", error);
        });

      return this.resizeAndBindVideos($(".vimeoembed"));
    }
  },
  initSocialImages: function (socialShares) {
    var pinterestLoad, winHeight, winLeft, winParams, winTop, winWidth;
    console.log("Social Images loaded using jQuery ", $.fn.jquery);
    winWidth = 520;
    winHeight = 350;
    winTop = screen.height / 2 - winHeight / 2;
    winLeft = screen.width / 2 - winWidth / 2;
    winParams = "top=" + winTop + ",left=" + winLeft + ",toolbar=0,status=0,width=" + winWidth + ",height=" + winHeight;
    $.cachedScript = function (url, options) {
      options = $.extend(options || {}, {
        dataType: "script",
        cache: true,
        url: url,
      });
      return $.ajax(options);
    };
    pinterestLoad = $.cachedScript("//assets.pinterest.com/js/pinit.js");
    return pinterestLoad.done(function () {
      console.log("Pinterest JS loaded asynchronously");
      return $(socialShares).each(function () {
        var $el, customImageDescription, customImageLink, customImageURL, pinterestMap, uriEncodedImageLink, _ref, _ref1;
        $el = $(this);
        $el.css("display", "");
        customImageLink = $el.data("config").customImageLink;
        customImageURL = $el.data("config").customImageURL;
        customImageDescription = (_ref = $("#" + $el.data("config").containerID).find(".image-info")) != null ? ((_ref1 = _ref.text()) != null ? _ref1.trim() : void 0) : void 0;
        uriEncodedImageLink = encodeURIComponent(customImageLink);
        pinterestMap = {
          url: customImageLink,
          media: customImageURL,
        };
        if ((customImageDescription != null ? customImageDescription.length : void 0) > 0) {
          pinterestMap.description = customImageDescription;
        }
        $el.find(".social-icon.pinterest").click(function () {
          return PinUtils.pinOne(pinterestMap);
        });
        $el.find(".social-icon.facebook").click(function () {
          return window.open("http://www.facebook.com/sharer.php?s=100&p[url]=" + uriEncodedImageLink, "sharer", winParams);
        });
        $el.find(".social-icon.twitter").click(function () {
          return window.open("http://www.twitter.com/share?url=" + uriEncodedImageLink, "sharer", winParams);
        });
        return $el.find(".social-icon.googleplus").click(function () {
          return window.open("http://plus.google.com/share?url=" + uriEncodedImageLink, "sharer", winParams);
        });
      });
    });
  },
  resizeAndBindVideos: function (videos) {
    return this.resizeVideos(videos);
  },
  resizeVideos: function (videos) {
    var mustResize;
    mustResize = false;
    $(videos).each(function () {
      var $el;
      $el = $(this);

      if ($el.prop("tagName") === "IFRAME") {
        $el = $el.parent();
      }
      if ($(window).width() >= 750 && !$el.parents(".balanced--flex")) {
        mustResize = true;
      }
      if ($("iframe", $el).attr("width") > $el.width()) {
        return $el.addClass("fluid");
      } else {
        return $el.removeClass("fluid");
      }
    });
    if (mustResize) {
      document.querySelectorAll('#bd > .container > .row:not(.balanced--flex) > [class*="-body"] > [class*="col-md-"]:not(.col-md-12)').forEach(el => new EqualizeBottoms(el));
      document.querySelectorAll('#bd > .container > .row:not(.balanced--flex) > [class*="col-md-"]:not(.col-md-12)').forEach(el => new EqualizeBottoms(el));
      document.querySelectorAll(".columnsBootstrap .youtubeembed").forEach(el => {
        if (el.closest(".row.balanced") && !el.closest(".row.balanced").classList.contains("balanced--flex")) {
          Array.from(el.closest(".row.balanced").querySelectorAll('[class*="col-md-"]')).forEach(child => {
            child.style.minHeight = "";
            new EqualizeBottoms(el);
          });
        }
      });
    }
    return false;
  },
};
