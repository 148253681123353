/**
 * Class for equalizing the bottom position of elements with the class `.equalize-bottoms`.
 * This class adjusts the height of each element so that their bottom edges align at the same vertical position.
 *
 * @class EqualizeBottoms
 */
class EqualizeBottoms {
  /**
   * Creates an instance of EqualizeBottoms.
   * Queries the document for elements with the `.equalize-bottoms` class and initializes their heights to align their bottoms.
   *
   * @constructor
   * @param {number|string} bottom The desired bottom position for the elements. If a non-numeric value is provided, it defaults to 0.
   */
  constructor(bottom) {
    /**
     * NodeList of document elements with the `.equalize-bottoms` class.
     * @type {NodeList}
     */
    this.elements = document.querySelectorAll(".equalize-bottoms");

    /**
     * The bottom position to equalize the elements to. If a non-numeric value is passed to the constructor, it defaults to 0.
     * @type {number}
     */
    this.bottom = isNaN(bottom) ? 0 : bottom;

    // Initialize the elements' heights.
    this.init();
  }

  /**
   * Initializes the equalization of elements' heights.
   * If `this.bottom` is 0, it calculates the maximum bottom position of the elements.
   * It then adjusts each element's height to ensure their bottom edges are aligned at `this.bottom`.
   *
   * @method
   */
  init() {
    // If bottom is 0, find the maximum bottom edge of all elements.
    if (this.bottom === 0) {
      this.elements.forEach(el => {
        el.style.height = "auto";
        const elBottom = el.offsetTop + el.offsetHeight;
        this.bottom = Math.max(this.bottom, elBottom);
      });
    }

    // Adjust the height of each element so their bottom edges align at `this.bottom`.
    this.elements.forEach(el => {
      const tallest = this.bottom - el.offsetTop - el.offsetHeight + el.clientHeight;
      if (el.clientHeight !== tallest) {
        el.style.height = `${tallest}px`;
      }
    });
  }
}

export default EqualizeBottoms;
