const toggleNavOnActiveLinkClick = function () {
  const activeNavLink = document.querySelector(".left-nav__link--active");
  if (!activeNavLink) return;

  activeNavLink.addEventListener("click", function (e) {
    e.preventDefault();

    const navList = document.querySelector(".left-nav__list");
    if (navList) {
      navList.classList.toggle("left-nav__list--open");
    }
  });
};

export default {
  init: function () {
    let mql = window.matchMedia("(max-width: 767px)");
    function isMobile(e) {
      if (e.matches) {
        toggleNavOnActiveLinkClick();
      }
    }
    mql.addEventListener("change", isMobile);
    isMobile(mql);
  },
};
