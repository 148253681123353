import media from "./cq5-media.js";
import columns from "./cq5-columns.js";
import cookiebanner from "./cq5-cookiebanner.js";
import dynamicjoin from "./cq5-dynamic-join.js";
import eventcalendar from "./cq5-eventcalendar.js";
import inclusivityLeftNav from "./cq5-inclusivity-left-nav.js";
import leftnav from "./cq5-left-navi.js";
import meetingsLeftNav from "./cq5-meetings-left-nav.js";
import megamenu from "./cq5-megamenu.js";
import mscNav from "./cq5-msc-nav.js";
import newsticker from "./cq5-newsticker.js";
import responsiveTables from "./cq5-responsive-tables.js";
import slider from "./cq5-slider.js";
import topnav from "./cq5-topnav.js";
import twitterfeed from "./cq5-twitterfeed.js";

// utility functions
import safeInit from "./utils/safe-init.js";
import groupElementsByAttribute from "./utils/group-elements-by-attribute.js";
import createExecutor from "./utils/execute-grouped-methods.js";

// these modules will be processed using DOM based routing (i.e. data-composer='slider' data-action='init'), without delay
// Only place modules here that are above the fold / on almost all pages, i.e. top-nav, search-bar
const routedModules = {};
routedModules.columns = columns;
routedModules.cookiebanner = cookiebanner;
routedModules.dynamicjoin = dynamicjoin;
routedModules.eventcalendar = eventcalendar;
routedModules.inclusivityLeftNav = inclusivityLeftNav;
routedModules.leftnav = leftnav;
routedModules.meetingsLeftNav = meetingsLeftNav;
routedModules.media = media;
//routedModules.megamenu = megamenu; // called directly
routedModules.mscNav = mscNav;
routedModules.newsticker = newsticker;
routedModules.slider = slider;
routedModules.topnav = topnav;
routedModules.twitterfeed = twitterfeed;

// these modules will be processed if any elements are found w/ the selector, using DOM based routing
// this helps us to reduce the size of the bundle. Modern browsers do these parallel requests very quickly
const modulesToInitialize = [
  { sel: "#protectContentLightBox", name: "cq5-protected-content", prm: () => import(/* webpackChunkName: "cq5-protected-content" */ "./cq5-protected-content.jsx") },
  { sel: "#protectContentInchemistry", name: "inchemistry-protected-content", prm: () => import(/* webpackChunkName: "inchemistry-protected-content" */ "./inchemistry-protected-content.jsx") },
  { sel: '[data-composer="youtube"]', name: "youtube", prm: () => import(/* webpackChunkName: "cq5-youtube" */ "./cq5-youtube.jsx") },
  { sel: '[data-composer="filteredlist"]', name: "filteredlist", prm: () => import(/* webpackChunkName: "cq5-filteredlist" */ "./cq5-filteredlist.jsx") },
  { sel: ".masterTab", name: "linktotab", prm: () => import(/* webpackChunkName: "cq5-link-to-tab" */ "./cq5-link-to-tab.js") },
  { sel: "#notify-renewal", name: "welcome", prm: () => import(/* webpackChunkName: "cq5-welcome" */ "./cq5-welcome.js") },
  { sel: '[data-composer="lightbox"]', name: "lightbox", prm: () => import(/* webpackChunkName: "cq5-lightbox" */ "./cq5-lightbox.js") },
  { sel: '[data-composer="lightboxcontent"]', name: "lightboxcontent", prm: () => import(/* webpackChunkName: "cq5-lightbox-content" */ "./cq5-lightbox-content.js") },
  { sel: '[data-composer="targetedContent"]', name: "targetedContent", prm: () => import(/* webpackChunkName: "cq5-targeted-content" */ "./cq5-targeted-content.js") },
  { sel: '[data-composer="webinarRegistration"]', name: "webinarRegistration", prm: () => import(/* webpackChunkName: "cq5-webinar-registration" */ "./cq5-webinar-registration.js") },
  { sel: '[data-composer="abstractcontact"]', name: "abstractcontact", prm: () => import(/* webpackChunkName: "cq5-abstract-contact" */ "./cq5-abstract-contact.js") },
  { sel: '[data-composer="carousel4up"]', name: "carousel4up", prm: () => import(/* webpackChunkName: "cq5-carousel-4up" */ "./cq5-carousel-4up.js") },
  { sel: '[data-composer="motwrollup"]', name: "motwrollup", prm: () => import(/* webpackChunkName: "cq5-motw-rollup" */ "./cq5-motw-rollup.js") },
  { sel: '[data-composer="accordion"]', name: "accordion", prm: () => import(/* webpackChunkName: "cq5-accordion" */ "./cq5-accordion.js") },
  { sel: '[data-composer="articlestickysidebar"]', name: "articlestickysidebar", prm: () => import(/* webpackChunkName: "cq5-articlestickysidebar" */ "./cq5-articlestickysidebar.js") },
  { sel: '[data-composer="rss"]', name: "rss", prm: () => import(/* webpackChunkName: "cq5-rss-external" */ "./cq5-rss-external.js") },
  { sel: '[data-composer="brightcove"]', name: "cq5-brightcove", prm: () => import(/* webpackChunkName: "cq5-brightcove" */ "./cq5-brightcove.js") },
  { sel: '[data-toggle="dropdown"]', name: "cq5-bootstrap-dropdown", prm: () => import(/* webpackChunkName: "cq5-bootstrap-dropdown" */ "./cq5-bootstrap-dropdown.js") },
  { sel: '[data-toggle="modal"]', name: "cq5-bootstrap-modal", prm: () => import(/* webpackChunkName: "cq5-bootstrap-modal" */ "./cq5-bootstrap-modal.js") },
];

document.addEventListener("DOMContentLoaded", function () {
  safeInit(megamenu)();
  safeInit(responsiveTables)(document.querySelectorAll("table.responsive"));
  safeInit(columns)();

  if (document.querySelectorAll(".alert-banner__cookie").length > 0) {
    safeInit(cookiebanner)();
  }

  if (document.querySelectorAll("video,audio").length > 0) {
    safeInit(media)();
  }
  // load protected content
  if (document.querySelectorAll("body.body--protected, body.butler").length > 0) {
    import(/* webpackChunkName: "cq5-protected-content" */ "./cq5-protected-content.jsx").then(({ default: module }) => {
      safeInit(module)();
    });
  }
  /**
   * BEGIN conditional LAZY-LOAD modules
   * Place modules here that aren't on most pages - it will make the bundle smaller
   */
  const initializeModule = ({ sel, name, prm }) => {
    const elements = document.querySelectorAll(sel);
    if (elements.length <= 0) {
      return Promise.resolve(); // Return a resolved promise for empty elements
    }

    return prm()
      .catch(error => {
        console.error(`Error preloading ${name}:`, error);
      })
      .then(({ default: module }) => {
        const modules = {};
        modules[name] = module;
        const executor = createExecutor(modules, modulesToInitialize);
        executor(groupElementsByAttribute(elements));
      });
  };

  // Run all promises concurrently
  Promise.all(modulesToInitialize.map(initializeModule))
    .then(() => {})
    .catch(err => {
      console.error("Lazy module load failed :", err);
    });

  /**
   * END conditional LAZY-LOAD modules
   */

  /*
     BEGIN DOM BASED ROUTING SECTION
     The data-composer attribute should point to the require-defined JS controller (required)
     The data-action attribute should reference a method of that controller, which will be passed an array of dom elements .
        (Defaults to init)
     Examples can be found in the lightboxContent, lightboximage, youtubeembed, rss,
     news highlight, members handbook components, and more!
     The data-config object should contain a JSON object with configuration parameters (optional)
     See http://www.paulirish.com/2009/markup-based-unobtrusive-comprehensive-dom-ready-execution/ for inspiration
     */

  const executor = createExecutor(routedModules, modulesToInitialize);
  executor(groupElementsByAttribute(document.querySelectorAll("[data-composer]")));

  /*
     END DOM BASED ROUTING SECTION
     */
});
