import columns from "./cq5-columns.js";

export default {
  init: function (_twitterfeed) {
    if (window.innerWidth <= 769) {
      return;
    }
    twttr.events.bind("rendered", function (event) {
      // Do a column match height after the feed is loaded.
      columns.resetHeight();
      columns.equalizeHeight();
    });
  },
};
