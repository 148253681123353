const newsTicker = (el, delay = 6000) => {
  let newsList = null;

  const initTicker = el => {
    stopTicker(el);
    el.items = el.querySelectorAll("h2");

    // hide all items (except first one)
    Array.from(el.items).forEach((item, index) => {
      if (index !== 0) item.style.display = "none";
    });

    // current item
    el.currentitem = 0;
    startTicker(el);
  };

  const startTicker = el => {
    el.tickfn = setInterval(() => {
      doTick(el);
    }, delay);
  };

  const stopTicker = el => {
    clearInterval(el.tickfn);
  };

  const pauseTicker = el => {
    el.pause = true;
  };

  const resumeTicker = el => {
    el.pause = false;
  };

  const doTick = el => {
    // don't run if paused
    if (el.pause) return;
    // pause until animation has finished
    el.pause = true;
    // hide current item
    fadeOut(el.items[el.currentitem], () => {
      // move to next item and show
      el.currentitem = ++el.currentitem % el.items.length;
      fadeIn(el.items[el.currentitem], () => {
        el.pause = false;
      });
    });
  };

  const doNextTick = el => {
    fadeOut(el.items[el.currentitem], () => {
      // move to next item and show
      el.currentitem = ++el.currentitem % el.items.length;
      fadeIn(el.items[el.currentitem]);
    });
  };

  const doPrevTick = el => {
    fadeOut(el.items[el.currentitem], () => {
      // move to next item and show
      el.currentitem = --el.currentitem % el.items.length;
      if (el.currentitem < 0) {
        el.currentitem = el.items.length - 1;
      }
      fadeIn(el.items[el.currentitem]);
    });
  };

  const fadeOut = (el, callback) => {
    el.style.transition = "opacity 0.5s ease-out";
    el.style.opacity = 0;
    setTimeout(() => {
      el.style.display = "none";
      callback();
    }, 500);
  };

  const fadeIn = (el, callback) => {
    el.style.display = "";
    el.style.transition = "opacity 0.5s ease-in";
    el.style.opacity = 1;
    if (callback) setTimeout(callback, 500);
  };

  document.querySelectorAll(".newsticker").forEach(el => {
    if (el.nodeName.toLowerCase() !== "div") return;
    initTicker(el);
    newsList = el;

    el.addEventListener("mouseover", () => pauseTicker(el));
    el.addEventListener("mouseout", () => resumeTicker(el));
  });

  document.querySelectorAll(".announcement-nav").forEach(el => {
    el.addEventListener("mouseover", () => pauseTicker(newsList));
    el.addEventListener("mouseout", () => resumeTicker(newsList));
  });

  // Handle next click
  document.querySelectorAll(".next").forEach(el => {
    el.addEventListener("click", e => {
      e.preventDefault();
      doNextTick(newsList);
    });
  });

  // Handle previous click
  document.querySelectorAll(".prev").forEach(el => {
    el.addEventListener("click", e => {
      e.preventDefault();
      doPrevTick(newsList);
    });
  });
};

export default {
  init: function (elem) {
    var strAnmntBtns = "&nbsp;";
    strAnmntBtns +=
      '<span class="announcement-nav"><a href="#" class="prev" aria-label="Previous Announcement"><img src="' + acs.assetUrl + 'images/homepage/left-grey-arrow.gif" align="absmiddle" alt="previous announcement" border="0" /></a>';
    strAnmntBtns += "<span>&nbsp;|&nbsp;</span>";
    strAnmntBtns += '<a href="#" class="next" aria-label="Next Announcement"><img src="' + acs.assetUrl + 'images/homepage/right-grey-arrow.gif" align="absmiddle" alt="Next announcement" border="0"  /></a></span>';

    var allAnnouncementsEl = document.querySelector("#announcements-all"),
      announcementBarEl = document.querySelector("#announcement-bar");

    if (!allAnnouncementsEl || !announcementBarEl) {
      return; // these elements are required
    }

    // hide the full announcements list on load
    allAnnouncementsEl.style.display = "none";
    allAnnouncementsEl.insertAdjacentHTML("afterend", '<br class="clearboth" />');

    // add progressive enhancement to the bar
    document.querySelector("#announcement-bar")?.classList.add("enhanced");

    // write the buttons to the screen
    document.querySelector(".announcement-sub > strong")?.insertAdjacentHTML("afterend", strAnmntBtns);

    document.querySelector("#headlines")?.insertAdjacentHTML("afterend", '<p class="view-all-announcements"><a href="#">View All</a></p>');

    document.querySelector(".view-all-announcements > a")?.addEventListener("click", function (e) {
      e.preventDefault();
      if (allAnnouncementsEl.offsetParent !== null) {
        this.textContent = "View All";
        allAnnouncementsEl.style.display = "none";
        announcementBarEl.querySelector(".announcement-nav").style.display = "";
        announcementBarEl.querySelector(".newsticker").style.display = "";
      } else {
        this.textContent = "Hide";
        announcementBarEl.querySelector(".announcement-nav").style.display = "none";
        announcementBarEl.querySelector(".newsticker").style.display = "none";
        allAnnouncementsEl.style.display = "block";
      }
    });

    var newsItems = allAnnouncementsEl?.querySelectorAll("h2") || [];
    var clonedNewsItems = Array.from(newsItems).map(item => item.cloneNode(true));

    document.querySelectorAll(".home #headlines").forEach(headline => {
      headline.innerHTML = clonedNewsItems.map(item => item.outerHTML).join("") || "";
      const delay = elem?.dataset?.delay || 6000;
      newsTicker(headline, delay);
    });
  },
};
