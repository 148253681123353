// revealing module pattern
export default {
  init: init,
};

function init() {
  console.log("Topnav loaded");

  let mqlMin = window.matchMedia("(min-width: 415px)");
  function isMqlMin(e) {
    if (e.matches) {
      stickynav();
    }
  }
  mqlMin.addEventListener("change", isMqlMin);
  isMqlMin(mqlMin);

  let mqlMax = window.matchMedia("(max-width: 768px)");
  function isMqlMax(e) {
    if (e.matches) {
      mobileMenuInit();
    }
  }
  mqlMax.addEventListener("change", isMqlMax);
  isMqlMax(mqlMax);
}

/**
 * If you need stuff to happen when mobile nav loads, just add them here
 */
function mobileMenuInit() {
  attachEvents(".acsnavcontainer ul li a span");
}

/**
 * Make desktop nav sticky
 */
function stickynav() {
  var stickynav = document.querySelector(".acstopnav");
  if (!stickynav) {
    return;
  }
  var stickynavOffset = stickynav.getBoundingClientRect().top + document.documentElement.scrollTop;

  window.addEventListener("scroll", function () {
    if (stickynavOffset - document.documentElement.scrollTop <= 0) {
      stickynav.classList.add("fixed-nav");
    } else {
      stickynav.classList.remove("fixed-nav");
    }
  });
}

/**
 * Mobile nav items require expand/collapse controls
 * Simple toggling of the "active" class should do it
 */
function attachEvents(className) {
  document.querySelectorAll(className).forEach(function (element) {
    element.addEventListener("click", function (e) {
      e.preventDefault();

      var parentListItem = element.closest("li");
      parentListItem.classList.toggle("active");
    });
  });
}
