/**
 * Groups HTML elements by a composite key derived from their `data-composer` and `data-action` attributes.
 * Each element is categorized into an array based on these attributes. If an element does not have a
 * `data-composer` attribute, it is skipped. The `data-action` attribute defaults to "init" if not specified.
 * The result is an object where each key represents a unique combination of composer and action,
 * and the value is an array of elements that match this combination.
 *
 * @param {NodeListOf<Element>|Element[]} elements - A list or array of DOM elements to be grouped.
 * @returns {Object} An object with keys in the format "composer.action", each mapping to an array of elements
 *                   that share that composer and action. If an element only specifies a composer,
 *                   its action is assumed to be "init".
 *
 * @example
 * // Assuming HTML elements with data attributes data-composer and data-action
 * const elements = document.querySelectorAll('[data-composer]');
 * const grouped = groupElementsByAttribute(elements);
 * console.log(grouped); // Outputs: { "composer1.init": [Element, ...], "composer2.action1": [Element, ...], ... }
 */
function groupElementsByAttribute(elements) {
  const controllers = {};

  elements.forEach(element => {
    const composer = element.getAttribute("data-composer");
    if (!composer) {
      return;
    }
    const action = element.getAttribute("data-action") || "init";
    const key = composer + "." + action;

    if (!controllers[key]) {
      controllers[key] = [];
    }
    controllers[key].push(element);
  });

  return controllers;
}

export default groupElementsByAttribute;
