/**
 * Initializes a module safely. If the module's `init` method throws an error, it catches and logs the error to the console.
 * This function uses currying to first take the module to be initialized and then returns a function that takes the initialization arguments.
 *
 * @param {Object} module - The module object that has an `init` method. This object should contain the functionality that needs to be initialized.
 * @returns {Function} A curried function that takes any number of arguments to be passed to the module's `init` method. When called, it attempts to safely initialize the module with those arguments.
 *
 * @example
 * // Assuming `megamenu` is an object with an `init` method that initializes a mega menu
 * const initMegamenu = safeInit(megamenu);
 * initMegamenu(domElements); // `domElements` would be the arguments required by `megamenu.init`
 */
function safeInit(module) {
  return function (...args) {
    try {
      module.init(...args);
    } catch (e) {
      console.error(e);
    }
  };
}

export default safeInit;
