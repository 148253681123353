import $ from "jquery";
import "./vendor/jquery_plugins/jquery.accessibleMegaMenu.js";

acs.menuListCount = 0;
acs.menuListMore = typeof acs.menuListMore !== "undefined" ? acs.menuListMore : true;
acs.menuListRebuild = typeof acs.menuListRebuild !== "undefined" ? acs.menuListRebuild : true;
acs.header = {};
acs.header.init = function () {
  // Get all the menu and more menu items
  var menuItems = document.querySelectorAll(".menu__item");
  var menuListWidth = 0;
  var menuLogoWidth = 187;
  var menuSearchWidth = 0;
  var menuLogo = document.querySelector(".menu__logo");
  menuLogoWidth = menuLogo ? menuLogo.offsetWidth : menuLogoWidth;
  var menuSearch = document.querySelector(".search-form");
  menuSearchWidth = menuSearch ? menuSearch.offsetWidth : menuSearchWidth;
  var menuMoreWidth = acs.menuListMore ? 85 : 0;
  var menuPaddingWidth = 40;
  var menuOthersWidth = menuLogoWidth + menuSearchWidth + menuMoreWidth + menuPaddingWidth;
  var acsMenuArray = [];
  var menuItem = {};
  var pagePath = window.location.pathname;

  // Highlight section to match url
  var menuLinks = document.querySelectorAll(".menu__link");
  menuLinks.forEach(menuLink => {
    var menuLinkPath = menuLink.getAttribute("href").split(".")[0];
    if (pagePath.indexOf(menuLinkPath) === 0) {
      menuLink.classList.add("menu__link--active");
    }
  });

  var subMenuLinks = document.querySelectorAll(".menu-sub__link");
  subMenuLinks.forEach(subMenuLink => {
    var subMenuLinkPath = subMenuLink.getAttribute("href").split(".")[0];
    if (pagePath.split(".")[0] === subMenuLinkPath) {
      subMenuLink.classList.add("menu-sub__link--active");
    }
  });

  menuItems.forEach(menuItem => {
    var menuItemWidth = menuItem.offsetWidth;
    var menuNoSub = menuItem.classList.contains("menu__no-sub");

    menuListWidth = menuListWidth + menuItemWidth;
    var menuItemObject = {
      item: menuItem,
      width: menuItemWidth,
      menuListWidth: menuListWidth,
      noSubMenu: menuNoSub,
    };
    acsMenuArray.push(menuItemObject);
  });

  if (window.matchMedia("(max-width:767px)").matches) {
    acs.header.mobileMenus(acsMenuArray);
  } else if (window.matchMedia("(min-width:768px)").matches) {
    acs.header.desktopMenus(menuOthersWidth, acsMenuArray);
  }

  window.matchMedia("(max-width:767px)").addEventListener("change", function (event) {
    if (event.matches) {
      acs.header.mobileMenus(acsMenuArray);
    }
  });

  window.matchMedia("(min-width:768px)").addEventListener("change", function (event) {
    if (event.matches) {
      acs.header.desktopMenus(menuOthersWidth, acsMenuArray);
    }
  });
};

acs.header.desktopMenus = function (menuOthersWidth, acsMenuArray) {
  window.addEventListener("myAccountMenu", function () {
    $("#member-menu").accessibleMegaMenu({
      /* prefix for generated unique id attributes, which are required
               to indicate aria-owns, aria-controls and aria-labelledby */
      uuidPrefix: "js--member-menu",

      /* css class used to define the megamenu styling */
      menuClass: "js--member-menu",

      /* css class for a top-level navigation item in the megamenu */
      topNavItemClass: "js--member-menu__item",

      /* css class for a megamenu panel */
      panelClass: "js--member-menu__sub",

      /* css class for a group of items within a megamenu panel */
      panelGroupClass: "js--member-menu-sub__list",

      /* css class for the hover state */
      hoverClass: "hover",

      /* css class for the focus state */
      focusClass: "focus",

      /* css class for the open state */
      openClass: "open",
    });
  });

  acs.header.resizeMenu(menuOthersWidth, acsMenuArray);
  acs.header.searchForm();

  var menu = document.querySelector(".menu");
  if (!!menu && menu.dataset.headerSlim === "true") {
    var header = document.querySelector(".acsHeader");
    document?.addEventListener("scroll", function () {
      if (!!header && header.getBoundingClientRect().y === 0) {
        header.classList.add("slim");
      } else {
        header.classList.remove("slim");
      }
    });
  }
};

acs.header.mobileMenus = function (acsMenuArray) {
  window.removeEventListener("resize", rebuildMenu);
  rebuildMenu();

  function rebuildMenu() {
    var menuListHtml = "";

    acsMenuArray.forEach(menuItemObject => {
      var itemAnchor = menuItemObject.item.innerHTML;
      var itemClassname = "menu__item";
      if (menuItemObject.noSubMenu) {
        itemClassname += " menu__no-sub";
      }
      menuListHtml += `<li class="${itemClassname}">${itemAnchor}</li>\n`;
    });
    if (acs.menuListRebuild) {
      document.querySelectorAll(".menu__list").forEach(element => (element.innerHTML = menuListHtml));
    }
  }

  // enable back link
  document.querySelectorAll(".hat__menu-back").forEach(element => {
    element.addEventListener("click", function () {
      document.querySelectorAll(".menu__sub").forEach(sub => sub.classList.remove("showit"));
      document.querySelectorAll(".menu__link").forEach(link => link.classList.remove("hide"));
      document.querySelectorAll(".menu__item").forEach(link => link.classList.remove("hide", "showit"));
      document.querySelectorAll(".hat__menu-back").forEach(back => back.classList.remove("showit"));
      document.querySelectorAll(".menu__logo").forEach(logo => logo.classList.remove("hide"));
    });
  });

  // show sub menu
  document.querySelectorAll(".menu__link").forEach(element => {
    element.addEventListener("click", function (event) {
      if (element.nextElementSibling && element.nextElementSibling.classList.contains("menu__sub")) {
        event.preventDefault();
      }
      document.querySelectorAll(".menu__item").forEach(item => item.classList.add("hide"));
      element.parentElement.classList.remove("hide");
      element.parentElement.classList.add("showit");
      element.classList.add("hide");
      if (element.nextElementSibling) {
        element.nextElementSibling.classList.add("showit");
      }
      document.querySelectorAll(".menu__logo").forEach(logo => logo.classList.add("hide"));
      document.querySelectorAll(".hat__menu-back").forEach(back => back.classList.add("showit"));
    });
  });

  // show search
  document.querySelectorAll(".hat__search-button").forEach(element => {
    element.addEventListener("click", function () {
      if (!element.classList.contains("showit")) {
        document.querySelectorAll(".menu-on").forEach(menu => menu.classList.remove("menu-on"));
        document.querySelectorAll(".showit").forEach(showit => showit.classList.remove("showit"));
        document.querySelectorAll(".hide").forEach(hide => hide.classList.remove("hide"));
        document.body.classList.remove("noscroll");
      }
      document.querySelectorAll(".hat__menu-back").forEach(back => back.classList.remove("showit"));
      document.querySelectorAll(".menu__logo").forEach(logo => logo.classList.remove("hide"));
      document.body.classList.toggle("noscroll");
      document.querySelectorAll(".hat__search-button").forEach(button => button.classList.toggle("showit"));
      document.querySelector("#search")?.classList.toggle("showit");
      document.querySelectorAll(".menu").forEach(menu => menu.classList.toggle("showit", "search-on"));
      document.querySelectorAll(".acsHeader").forEach(header => header.classList.toggle("menu-on"));
      document.querySelectorAll(".menu__list").forEach(list => list.classList.add("hide"));
    });
  });

  // show menu
  document.querySelectorAll(".hat__menu-button").forEach(element => {
    element.addEventListener("click", function () {
      if (!element.classList.contains("showit")) {
        document.querySelectorAll(".menu-on").forEach(menu => menu.classList.remove("menu-on"));
        document.querySelectorAll(".showit").forEach(showit => showit.classList.remove("showit"));
        document.querySelectorAll(".hide").forEach(hide => hide.classList.remove("hide"));
        document.querySelectorAll(".menu").forEach(menu => menu.classList.remove("search-on"));
        document.body.classList.remove("noscroll");
      }
      document.querySelectorAll(".menu__list").forEach(list => list.classList.remove("hide"));
      document.querySelectorAll(".menu__join").forEach(join => join.classList.toggle("showit"));
      document.querySelectorAll(".hat__menu-back").forEach(back => back.classList.remove("showit"));
      document.querySelectorAll(".menu__logo").forEach(logo => logo.classList.remove("hide"));
      document.body.classList.toggle("noscroll");
      document.querySelectorAll(".hat__menu-button").forEach(button => button.classList.toggle("showit"));
      document.querySelectorAll(".menu").forEach(menu => menu.classList.toggle("showit"));
      document.querySelectorAll(".nav--main__language").forEach(menuLanguage => menuLanguage.classList.toggle("showit"));
    });
  });
};

acs.header.searchForm = function () {
  if (this.isMobileOnly) {
    return;
  }

  var search = document.querySelector("#search");

  document.querySelectorAll(".search-form").forEach(element => {
    element.addEventListener("click", function () {
      document.querySelectorAll(".menu__join").forEach(join => join.classList.add("hide"));
      document.querySelectorAll(".menu__list").forEach(list => list.classList.add("hide"));
      search?.classList.add("showit");
    });
  });

  document.querySelectorAll(".search-form__close").forEach(element => {
    element.addEventListener("click", function (event) {
      event.stopPropagation();
      search?.classList.remove("showit");
      document.querySelectorAll(".menu__list").forEach(list => list.classList.remove("hide"));
      document.querySelectorAll(".menu__join").forEach(join => join.classList.remove("hide"));
    });
  });
};

acs.header.resizeMenu = function (menuOthersWidth, acsMenuArray) {
  var newMenuListCount = 0;
  var search = document.querySelector("#search");

  rebuildMenu();

  if (acs.menuListMore) {
    window.addEventListener("resize", rebuildMenu);
  }

  function rebuildMenu() {
    var isSearchOn = search ? search.classList.contains("showit") : false;
    document.querySelectorAll(".members").forEach(member => member.classList.remove("loading-button"));
    document.querySelectorAll(".showit").forEach(element => {
      if (element.id !== "search") {
        element.classList.remove("showit");
      }
    });

    if (!isSearchOn) {
      document.querySelectorAll(".menu").forEach(menu => menu.classList.remove("search-on"));
      document.body.classList.remove("noscroll");
      document.querySelectorAll(".menu__list").forEach(list => list.classList.remove("hide"));
    }

    var menu = document.querySelector(".menu");
    var menuListHtml = "";
    var menuListMoreHtml = "";
    var menuLeftWidth = menu ? menu.offsetWidth - menuOthersWidth : 0; // subtracting width of more menu

    menuListHtml = "";
    menuListMoreHtml =
      '<li class="menu__item menu__more js--menu__item" role="none">' +
      '<a href="#" id="more-button" role="menuitem" aria-haspopup="true" aria-owns="more-menu" aria-controls="more-menu" aria-expanded="false" class="menu__button">More</a>' +
      '<ul id="more-menu" role="group" aria-expanded="false" aria-hidden="true" aria-labelledby="more-button" class="more-menu js--menu__sub">';

    acsMenuArray.forEach(function (menuItemObject) {
      if (menuItemObject.isMobileOnly) {
        return;
      }
      if (acs.menuListMore) {
        if (menuItemObject.noSubMenu) {
          menuListMoreHtml += '<li class="more-menu__item">' + menuItemObject.item.innerHTML.replace(/menu__link/g, "more-menu__link").replace(/js--menu__sub/g, "") + "</li>\n";
          return;
        }
        if (menuItemObject.menuListWidth <= menuLeftWidth) {
          menuListHtml += '<li class="menu__item js--menu__item" role="none">' + menuItemObject.item.innerHTML + "</li>\n";
          newMenuListCount++;
        } else {
          menuListMoreHtml += '<li class="more-menu__item">' + menuItemObject.item.innerHTML.replace(/menu__link/g, "more-menu__link").replace(/js--menu__sub/g, "") + "</li>\n";
        }
      } else {
        menuListHtml += '<li class="menu__item js--menu__item" role="none">' + menuItemObject.item.innerHTML + "</li>\n";
        newMenuListCount++;
      }
    });

    menuListMoreHtml += "</ul></li>";

    menuListMoreHtml = acs.menuListMore ? menuListMoreHtml : "";
    menuListHtml = menuListHtml + menuListMoreHtml;

    if (acs.menuListRebuild) {
      document.querySelectorAll(".menu__list").forEach(element => (element.innerHTML = menuListHtml));
    }

    if (newMenuListCount != acs.menuListCount) {
      acs.menuListCount = newMenuListCount;

      $("#main-menu").accessibleMegaMenu({
        /* prefix for generated unique id attributes, which are required
                   to indicate aria-owns, aria-controls and aria-labelledby */
        uuidPrefix: "js--menu",

        /* css class used to define the megamenu styling */
        menuClass: "js--menu",

        /* css class for a top-level navigation item in the megamenu */
        topNavItemClass: "js--menu__item",

        /* css class for a megamenu panel */
        panelClass: "js--menu__sub",

        /* css class for a group of items within a megamenu panel */
        panelGroupClass: "js--menu-sub__list",

        /* css class for the hover state */
        hoverClass: "hover",

        /* css class for the focus state */
        focusClass: "focus",

        /* css class for the open state */
        openClass: "open",
      });
    }

    document.querySelectorAll(".menu__list").forEach(element => element.classList.add("loaded"));
  }
};

export default {
  init: () => {
    if (!!acs && !!acs.page && !!acs.page.path && acs.page.path.indexOf("/inchemistry/en") <= -1) {
      acs.header.init();
    }
  },
};
