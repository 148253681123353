export default {
  init: function () {
    import("../sass/components-standalone/eventcalendar.scss"); // load through webpack's SCSS loader - async
    console.log("Event Calendar loaded");

    document.querySelectorAll(".checkbox-menu input[type='checkbox']").forEach(element => {
      element.addEventListener("change", function () {
        let closestLi = this.closest("li");
        if (this.checked) {
          closestLi.classList.add("active");
        } else {
          closestLi.classList.remove("active");
        }
      });
    });

    document.querySelectorAll(".allow-focus").forEach(element => {
      element.addEventListener("click", function (e) {
        e.stopPropagation();
      });
    });

    document.querySelectorAll(".eventcalendar-form-filter").forEach(element => {
      element.addEventListener("submit", function (e) {
        e.preventDefault();
        let target = e.target.dataset.base + ".user";
        document.querySelectorAll("input[name='typeFilterChecked']").forEach(type => {
          if (type.checked) {
            target += "." + type.value;
          }
        });
        let locations = document.querySelectorAll("input[name='locationFilterChecked']");
        locations.forEach(location => {
          if (location.checked) {
            target += "." + location.value;
          }
        });
        target += ".html";
        window.location.assign(target);
      });
    });
  },
};
