export default {
  init: function (responsiveTables) {
    if (responsiveTables.length <= 0) {
      return;
    }
    // Loop through each found table
    responsiveTables.forEach(function (table) {
      // Create a new <div> element
      var divWrapper = document.createElement("div");
      // Add the class 'table-responsive' to the created <div>
      divWrapper.classList.add("table-responsive");

      // Wrap the table with the created <div>
      table.parentNode.insertBefore(divWrapper, table);
      divWrapper.appendChild(table);
    });
  },
};
